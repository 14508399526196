<template>
<footer>
    <div>
        <h3>Coordonnées</h3>
        <p>OFPAS</p>
        <p>42 grande rue 89460 Prégilbert</p>
        <p>06 71 16 42 01</p>
        <p>ofpas-vilain.stephane@orange.fr</p>
        <a href="https://www.linkedin.com/in/st%C3%A9phane-vilain-55136b216/" target="_blank">
            <i class="fab fa-linkedin"></i> Stéphane Vilain
        </a>
    </div>
    <div>
        <h3>Références administratives</h3>
        <p>N° SIRET : 89752874100035</p>
        <p>N° Organisme de formation : 27890159689</p>
        <div class="footer-images">
            <img src="../assets/com-ea-logo.png" alt="">
            <img src="../assets/qualiopi.png" alt="">
        </div>
        <p>Com’EA est certifié Qualiopi au titre des actions de formation</p>
    </div>

</footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style lang='scss' scoped>
    footer {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 1rem;
        background-color: #333;
        color: #f1f1f1;
        box-shadow: 0 0 10px #555;

        & h3 {
            margin-bottom: .4rem;
        }

        & p {
            font-weight: 100;
        }

        & a {
            font-weight: 100;
            text-decoration: none;
            color: #f1f1f1;
            transition: .3s;

            &:hover {
                color: #0499D2;
            }
        }

        & img {
            height: 40px;
            margin: .4rem;
        }
    }

@media screen and (max-width: 670px) {
    footer {
        font-size: .8rem;
    }
}

@media screen and (max-width: 530px) {
    footer {
        flex-direction: column;
        align-items: center;

        & div:first-child {
            margin-bottom: 1rem;
        }
    }
}
</style>