<template>
    <main class="contact">
        <h1 class="contact__title">Nous contacter</h1>

        <div class="contact-container">
            <div class="contact__infos">
                <p>OFPAS</p>
                <p>12 route de Chassigny 89200 AVALLON</p>
                <a href="tel:+33671164201">06 71 16 42 01</a>
                <a href="mailto:ofpas-vilain.stephane@orange.fr">ofpas-vilain.stephane@orange.fr</a>
                <a href="https://www.linkedin.com/in/st%C3%A9phane-vilain-55136b216/" target="_blank">
                    <i class="fab fa-linkedin"></i> Stéphane Vilain
                </a>
            </div>

            <form class="contact__form" @submit.prevent="sendForm">
                <div class="contact__form__group w50">
                    <label class="contact__form__group__label" for="firstname">Prénom</label>
                    <input class="contact__form__group__input" type="text" name="firstname" v-model="firstname">
                </div>

                <div class="contact__form__group w50">
                    <label class="contact__form__group__label" for="lastname">Nom</label>
                    <input class="contact__form__group__input" type="text" name="lastname" v-model="lastname">
                </div>

                <div class="contact__form__group">
                    <label class="contact__form__group__label" for="email">Email</label>
                    <input class="contact__form__group__input" type="email" name="email" v-model="email">
                </div>

                <div class="contact__form__group">
                    <label class="contact__form__group__label" for="content">Votre message</label>
                    <textarea class="contact__form__group__textarea" name="content" v-model="content"></textarea>
                </div>

                <p class="contact__form__success" v-if="success">{{ success }}</p>
                <p class="contact__form__error" v-if="error">{{ error }}</p>

                <button class="contact__form__button" type="submit">Envoyer</button>
            </form>
        </div>
    </main>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Contact',

    data() {
        return {
            firstname: '',
            lastname: '',
            email: '',
            content: '',
            success: null,
            error: null,
        }
    },

    methods: {
        sendForm() {
            const formValues = {
                from: `${this.firstname} ${this.lastname}`,
                email: this.email,
                content: this.content,
            };
            axios
            .post('https://ofpas-stephane-database.herokuapp.com/messages/save', formValues)
            .then(response => {
                if(response.status === 201) {
                    this.firstname = '';
                    this.lastname = '';
                    this.email = '';
                    this.content = '';
                    this.success = 'Votre message a été envoyé.';
                } else {
                    this.error = 'Une erreur est survenue lors de l\'envoie du message, vérifiez les information renseignés.'
                }
            });
        }
    }
}
</script>

<style lang='scss'>
.contact {
    padding: 3rem;

    & h1 {
        color: #0499D2;
    }

    & .contact-container {
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    &__infos {
        display: flex;
        flex-direction: column;
        margin: 3rem;
        & p {
            margin: .4rem 0;

            &:first-child {
                font-weight: bold;
            }
        }

        & a {
            margin: .4rem 0;
            text-decoration: none;
            color: #000;
            transition: .3s;

            &:hover {
                color: #0499D2;
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__group {
            display: flex;
            flex-direction: column;
            margin: .4rem 0;

            &__label {
                margin-bottom: .2rem;
            }

            &__input {
                width: 300px;
                padding: .4rem;
            }

            &__textarea {
                width: 300px;
                height: 100px;
                padding: .4rem;
            }
        }

        &__button {
            margin: 1rem;
            padding: .4rem;
            background-color: #0499D2;
            border: none;
            border-radius: .4rem;
            color: #f1f1f1;
            box-shadow: 0 0 6px #999;
            cursor: pointer;
            transition: .3s;

            &:hover {
                background-color: #05648a;
            }
        }

        &__success {
            color: #1d6b1d;
        }

        &__error {
            color: #c03737;
        }
    }
}

</style>