<template>
    <main class="soutenir-detail">
        <h2 class="soutenir-detail__title">{{soutenir.title}}</h2>
        <p class="soutenir-detail__public">{{soutenir.public}}</p>
        <p class="soutenir-detail__dates"><p>{{soutenir.duration}}</p>
        <a class="soutenir-detail__link" v-if="soutenir.id !== 1" :href="`/docs/programmes/soutenir${soutenir.id}.pdf`" target="_blank">
            <i class="far fa-file-pdf"></i> Programme complet
        </a>
        <p class="soutenir-detail__content" v-html="soutenir.content"></p>
    </main>
</template>

<script>
import axios from 'axios'

export default {
    name: "SoutenirDetail",
    data() {
        return {
            soutenir: null
        }
    },
    mounted(){
        axios
        .get(`https://ofpas-stephane-database.herokuapp.com/interventions/soutenir/${this.$route.params.id}`)
        .then(response => {
            this.soutenir = response.data
        })
    },
}
</script>

<style lang='scss'>
.soutenir-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;

    &__title {
        text-align: center;
        font-size: 1.4rem;
        color: #ED038C;
    }

    &__public {
        font-weight: 100;
        margin: .4rem;
    }

    &__dates {
        text-align: center;
    }

    &__content {
        margin-top: 2rem;
        line-height: 1.6;

        & ul, & ol {
            margin-left: 1.2rem;
        }
    }

    &__link {
        margin-top: 2rem;
        padding: .6rem;
        border-radius: .4rem;
        background-color: #ED038C;
        color: #f1f1f1;
        text-decoration: none;
        transition: .3s;

        &:hover {
            background-color: #962969;
        }
    }
}

@media screen and (max-width: 850px) {
    .soutenir-detail {
        padding: 1.4rem;
    }
}
</style>