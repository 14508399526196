import Home from './components/Home'
import Soutenir from './components/Soutenir'
import SoutenirDetail from './components/SoutenirDetail'
import Former from './components/Former'
import FormerDetail from './components/FormerDetail'
import Articles from './components/Articles'
import ArticleDetail from './components/ArticleDetail'
import Contact from './components/Contact'

export default [
    {
        path: '/', 
        component: Home
    },
    {
        path: '/interventions/soutenir', 
        component: Soutenir
    },
    {
        path: '/interventions/soutenir/:id', 
        component: SoutenirDetail
    },
    {
        path: '/interventions/former', 
        component: Former
    },
    {
        path: '/interventions/former/:id', 
        component: FormerDetail
    },
    {
        path: '/articles', 
        component: Articles
    },
    {
        path: '/articles/:id', 
        component: ArticleDetail
    },
    {
        path: '/contact', 
        component: Contact
    }
]