<template>
    <div>
        <main class="home">
            <img src="../assets/stephane.jpg" alt="" />
            <p>
                Outiller les travailleurs sociaux est aujourd’hui une nécessité
                pour qu’ils puissent faire face à des publics de plus en plus
                complexes, à une évolution constante des politiques publiques et
                donc des attentes à leur égard.
            </p>
            <p>
                Former sans outiller viendrait alors à transmettre des
                techniques, des savoirs, sans mode d’emploi.
            </p>
            <p>
                Je propose donc des actions de formation et d’accompagnement des
                équipes qui se veulent avant tout opératoires et qui viennent
                d’abord questionner et faire évoluer les pratiques.
            </p>
            <p>
                Éducateur spécialisé de formation, j’ai ensuite occupé des
                postes de responsable de service, directeur, et enfin directeur
                général d’une association de protection de l’enfance.
            </p>
            <p>
                De ces multiples et riches expériences, j’ai accumulé les
                observations, les diagnostics, les expérimentations et surtout
                les rencontres avec des professionnels de grande qualité. Je
                tire de ce long parcours des connaissances, des technicités, des
                savoir-faire, que je souhaite mettre au profit du plus grand
                nombre.
            </p>
            <p>
                Et cela avec un certain savoir-être, des valeurs, des principes,
                des convictions, dont principalement trois qui fondent le socle
                de mes interventions :
            </p>
            <button @click="showText = !showText">
                <span v-if="showText === false">
                    <i class="fas fa-caret-down"></i> Lire la suite ...
                </span>
                <span v-if="showText === true">
                    <i class="fas fa-caret-up"></i> Voir moins ...
                </span>
            </button>
            <div v-if="showText">
                <ul>
                    <li>
                        Les parents doivent être davantage et totalement
                        associés au quotidien de leurs enfants et des
                        établissements et services qui les accueillent, et cela
                        pour répondre aux rapports ministériels, enquêtes,
                        ouvrages, qui viennent faire le constat de manquement
                        dans ce domaine depuis des décennies.
                    </li>
                    <li>
                        Les différents champs du social et du médico-social
                        doivent se doter d’outils et de services innovants pour
                        prendre en charge de manière plus adaptée et diversifiée
                        les personnes les plus en difficultés, les plus à la
                        marge.
                    </li>
                    <li>
                        Dans les ESMS, certaines pratiques anciennes méritent
                        que nous nous attardions à les questionner et à les
                        faire évoluer. Je pense ici aux notions de sanctions, de
                        référence éducative, de fin de prise en charge.
                    </li>
                </ul>
                <p>
                    A travers des propositions de soutien aux équipes, qu’elles
                    soient de direction ou de terrain, ainsi que des sessions de
                    formations spécifiques, j’ai créé OFPAS pour accompagner
                    l’ensemble des professionnels aux changements d’aujourd’hui
                    et de demain.
                </p>
                <p>
                    Je sais que les acteurs de l’action sociale sont en
                    recherche de concret et prêts à passer du dire au faire.
                    C’est dans cet esprit que j’axe mes interventions sur le
                    triptyque « soutenir, innover, co-construire ».
                </p>
                <p>
                    Soutenir : parce que sous-tenir, c’est « se tenir sous », en
                    point d’appui, en position d’étayage et c’est cette posture
                    que j’adopte dans le cadre nos interventions. Et c’est ce
                    vers quoi je propose aux participants de tendre dans leur
                    pratique quotidienne.
                </p>
                <p>
                    Innover : parce que c’est l’élément essentiel qui permet aux
                    professionnels d’envisager sans résistance l’adaptation
                    constante et nécessaire des prestations et des interventions
                    offertes aux personnes accompagnées .
                </p>
                <p>
                    Co-construire : parce que les professionnels doivent
                    inventer les outils de leurs interventions et faire que ces
                    instants de réflexions communes, de partage, de créativité
                    s’inscrivent dans leur pratique.
                </p>
                <p>
                    Mon approche s’appuie sur l’expérience de terrain des
                    intervenants, sur la clinique de la place développée par
                    Jean-Pierre Thomasset, sur différents champs disciplinaires
                    tels que les sciences de l’éducation, la sociologie, la
                    psychologie, tous venant étayer une vision globale par des
                    clés de compréhension complémentaires.
                </p>
            </div>
<!--        <div>
                <h2>Lieu de formation</h2>
                <div class="formation-box">
                    &lt;!&ndash; <img src="../assets/map.png" alt="" /> &ndash;&gt;
                    <div id="map"></div>
                    <div>
                        <h3>Pépinière d'entreprises de l'Auxerrois</h3>
                        <p>105 Rue des Mignottes, 89000 Auxerre</p>
                        <p style="padding-top: 1rem;">
                            <i class="far fa-clock"></i> 15min à pieds de la
                            gare SNCF
                        </p>
                        <p>
                            <i class="fas fa-utensils"></i> Restaurants et
                            hôtels à proximité
                        </p>
                    </div>
                </div>
            </div>-->
            <div v-if="slides.length >= 1">
                <h2>Ils nous font confiance</h2>
                <div class="slides-box">
                    <vueper-slides autoplay>
                      <vueper-slide
                        v-for="(slide, i) in slides"
                        :key="i"
                        :style="'background-color: #fff'">
                        <template #content>
                          <div class="vueperslide__content-wrapper" style="flex-direction: row">
                            <div style="display: flex; flex-direction: column; width: 70%;">
                              <h3>{{ slide.name }}</h3>
                              <span v-html="slide.content"></span>
                            </div>
                          </div>
                        </template>
                      </vueper-slide>
                    </vueper-slides>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import axios from "axios";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
// import L from "leaflet";
// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
// import { Icon } from "leaflet";

/*delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});*/

export default {
    name: "Home",
    components: { VueperSlides, VueperSlide },
    data() {
        return {
            showText: false,
            slides: [],
        };
    },
    mounted() {
        /*const mymap = L.map("map").setView(
            [47.80450439453125, 3.589047908782959],
            14
        );
        L.marker([47.80450439453125, 3.589047908782959]).addTo(mymap);
        L.tileLayer(
            "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
            {
                attribution:
                    'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                maxZoom: 18,
                id: "mapbox/streets-v11",
                tileSize: 512,
                zoomOffset: -1,
                accessToken:
                    "pk.eyJ1IjoiYmVuamFtaW5jaG9yb24iLCJhIjoiY2t3N3A3YWF3MGFsejJ1cW1lMTJ5YmE1OSJ9.Ekrw8v8nxjQH7CreSfMfOg",
            }
        ).addTo(mymap);*/

        axios
            .get("https://ofpas-stephane-database.herokuapp.com/partners")
            .then((response) => {
                this.slides = response.data;
            });
    },
};
</script>

<style lang="scss">
.home {
    padding: 3rem;

    & img {
        width: 300px;
        float: left;
        margin-right: 2rem;
        margin-bottom: 1rem;
    }

    & p {
        margin: 1rem 0;
    }

    & ul {
        margin-left: 1.2rem;

        & li {
            margin: 1rem 0;
        }
    }

    & button {
        margin-left: 2rem;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    & h2 {
        margin: 2rem 0;
        text-align: center;
    }

    & .formation-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        & #map {
            width: 50%;
            min-width: 300px;
            height: 400px;
            border-radius: 0.4rem;
        }

        & div {
            margin: 1rem;
        }
    }

    & .slides-box {
        margin: 0 auto;
        width: 70%;

        &
            .vueperslides:not(.no-shadow):not(.vueperslides--3d)
            .vueperslides__parallax-wrapper:before {
            box-shadow: none;
        }
        &
            .vueperslides:not(.no-shadow):not(.vueperslides--3d)
            .vueperslides__parallax-wrapper:after {
            box-shadow: none;
        }

        .vueperslides__arrow svg {
            stroke: #333;
        }

        .vueperslides__bullet .default {
            border: 1px solid #333;
        }
        .vueperslides__bullet--active .default {
            background: #333;
        }
    }
}

@media screen and (max-width: 670px) {
    .home {
        padding: 1.4rem;
    }
}
</style>
