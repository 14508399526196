<template>
<nav>
    <button @click="show = !show">
        <div v-if="show">
            <i class="fas fa-times"></i> Menu
        </div>
        <div v-if="!show">
            <i class="fas fa-bars"></i>
        </div>
    </button>
    <ul v-if="show">
        <li>
            <router-link to="/" class="link1">
                Accueil
            </router-link>
        </li>
        <li>
            <router-link to="/interventions/soutenir" class="link2">
                Soutenir
            </router-link>
        </li>
        <li>
            <router-link to="/interventions/former" class="link3">
                Former
            </router-link>
        </li>
        <li>
            <router-link to="/articles" class="link4">
                Articles
            </router-link>
        </li>
        <li>
            <router-link to="/contact" class="link5">
                Contact
            </router-link>
        </li>
    </ul>
</nav>
</template>

<script>
export default {
    name: 'Menu',
    data() {
        return {
            show: false
        }
    }
}
</script>

<style lang='scss'>
    nav {
        display: flex;

        & button {
            margin: 0 1rem;
            border: none;
            background: none;
            color: #f1f1f1;
            font-size: 1.4rem;
            z-index: 999;
            cursor: pointer;
            transition: .3s;

            &:hover {
                color: #999;
            }
        }

        & ul {
            position: absolute;
            top: 50px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            color: #000;
            background-color: #cdcdcd;
            padding: .6rem;
            box-shadow: 0 0 10px #555;

            & li {
                margin: 0 1rem;
                font-weight: normal;
                font-style: normal;
                transition: .3s;

                &:hover {
                    transform: scale(1.1);
                }

                & .link1 {
                    color: #3AB54A;
                    text-decoration: none;
                }

                & .link2 {
                    color: #ED038C;
                    text-decoration: none;
                }

                & .link3 {
                    color: #662C92;
                    text-decoration: none;
                }

                & .link4 {
                    color: #F15A2A;
                    text-decoration: none;
                }

                & .link5 {
                    color: #0499D2;
                    text-decoration: none;
                }
            }
        }
    }

@media screen and (max-width: 450px) {
    nav ul li {
        margin: 0 .4rem;
    }
}

@media screen and (max-width: 360px) {
    nav ul li {
        font-size: .8rem;
    }
}
</style>