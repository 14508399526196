<template>
    <main class="former">
        <h1>Former</h1>

        <div class="former__card" v-bind:key="index" v-for="(item, index) in former">
            <router-link class="former__card__link" :to="`/interventions/former/${item.id}`">
                <h2 class="former__card__link__title">{{ item.title }}</h2>
            </router-link>
        </div>
    </main>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Former',
    data(){
        return {
            former: null
        }
    },
    mounted(){
        axios
        .get('https://ofpas-stephane-database.herokuapp.com/interventions/former')
        .then(response => {
            this.former = response.data
        })
    }
}
</script>

<style lang='scss'>
.former {
    padding: 3rem;

    & h1 {
        color: #662C92;
    }

    &__card {
        margin: 1rem 0;
        border-radius: .6rem;
        box-shadow: 0 0 5px #999;

        &__link {
            color: #333;
            text-decoration: none;
            transition: .3s;

            &:hover {
                color: #662C92;
            }

            &__title {
                padding: 1rem;
                font-size: 1rem;
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .former {
        padding: 1.4rem;
    }
}
</style>