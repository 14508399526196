<template>
    <header class="header">
        <div class="header__image">
            <img src="../assets/logo_all.png" alt="OFPAS - Outiller et Former les Praticiens de l'Action Sociale">
            <h1 class="header__image__title">Stéphane Vilain</h1>
        </div>
        <div class="header__quote">
            <Menu/>
            <p>Tous les discours n'avancent point les choses : il faut faire et non pas dire <span>- Molière (1665)</span></p>
            <h2>Stéphane Vilain</h2>
        </div>
        
    </header>
</template>

<script>
import Menu from './Menu.vue'

export default {
    name: 'Header',
    components: {
        Menu
    }
}
</script>

<style lang='scss' scoped>
.header {
    width: 100%;

    &__image {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 4rem;
        background-image: url('https://images.unsplash.com/photo-1500964757637-c85e8a162699?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2403&q=80');
        background-size: cover;
        background-position: top;

        & img {
            height: 200px;
        }

        &__title {
            color: #333;
            text-shadow: 0 0 1px #f1f1f1;
            font-size: 3rem;
        }
    }

    &__quote {
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #333;
        padding-right: 2rem;
        color: #f1f1f1;
        font-weight: 100;
        font-style: italic;
        box-shadow: 0 0 10px #555;

        & span {
            margin-left: .4rem;
            font-style: normal;
            font-weight: 400;
        }

        & h2 {
            display: none;
        }
    }
}

@media screen and (max-width: 1100px) {
    .header {
        &__image {
            height: 200px;
            & img {
                height: 120px;
            }

            &__title {
                font-size: 2rem;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .header {
        &__image {
            justify-content: center;

            &__title {
                display: none;
            }
        }

        &__quote {
            & p {
                display: none;
            }

            & h2 {
                display: block;
            }
        }
    }
}
</style>