<template>
<div>
    <main class="posts">
        <h1>Articles</h1>

        <div v-if="posts.length" class="posts__container">
            <div class="posts__card" v-bind:key="index" v-for="(item, index) in posts">
                <h2 class="posts__card__title">{{ item.title }}</h2>
                <img class="posts__card__image" :src="item.image" alt="">
                <h3 class="posts__card__subtitle">{{ item.subtitle }}</h3>
                <router-link class="posts__card__link" :to="`/articles/${item.id}`">Lire</router-link>
            </div>
        </div>

        <p class="posts__no-article" v-if="!posts.length">Pas encore d'article publié.</p>
    </main>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Articles',
    data(){
        return {
            posts: []
        }
    },
    mounted(){
        axios
        .get('https://ofpas-stephane-database.herokuapp.com/posts')
        .then(response => {
            this.posts = response.data
        })
    }
}
</script>

<style lang='scss'>
.posts {
    padding: 3rem;

    & h1 {
        color: #F15A2A;
    }

    &__no-article {
        margin: 2rem;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__card {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem;
        border-radius: .6rem;
        box-shadow: 0 0 5px #999;

        &__title {
            padding: 1rem;
            font-size: 1.2rem;
        }

        &__image {
            width: 100%;
        }

        &__subtitle {
            padding: .4rem 1rem;
            font-size: 1rem;
            font-weight: 100;
            text-align: right;
        }

        &__link {
            margin-bottom: 1rem;
            padding: .4rem 1rem;
            background-color: #333;
            border-radius: .6rem;
            color: #f1f1f1;
            text-decoration: none;
            transition: .3s;

            &:hover {
                background-color: #F15A2A;
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .posts {
        padding: 1.4rem;
    }
}
</style>