import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Routes from './routes'
import dayjs from 'dayjs'
require('dayjs/locale/fr');
dayjs.locale('fr');
let advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);
import VueDayjs from 'vue-dayjs-plugin';
import VCalendar from 'v-calendar';
import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false

Vue.use(VueRouter);

Vue.use(VCalendar);

Vue.use(VueDayjs);

const router = new VueRouter({
  routes: Routes,
  mode: 'history'
});

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app');
