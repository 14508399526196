<template>
    <aside>
<!--        <div>
            <h2>Prochaines dates</h2>
            <router-link class="date-link" v-for="(item, index) in former" :key="index" :to="`/interventions/former/${item.id}`">
                <div class="next-date">
                    <p class="next-date__title">{{ item.title }}</p>
                    <p class="next-date__date">{{ $date(item.start_date).locale('fr').format('dddd D MMMM YYYY') }}</p>
                </div>
            </router-link>
        </div>-->
        <div>
            <h2>Documents</h2>
            <ul>
                <li>
                    <a href="/docs/catalogue_ofpas_2023.pdf" target="_blank">
                        <i class="far fa-file-pdf"></i> Catalogue
                    </a>
                </li>
                <li>
                    <a href="/docs/cgv_ofpas_23.pdf" target="_blank">
                        <i class="far fa-file-pdf"></i> Conditions Générale de Vente
                    </a>
                </li>
                <li>
                    <a href="/docs/tarifs_et_conditions_financement_23.pdf" target="_blank">
                        <i class="far fa-file-pdf"></i> Tarifs et conditions de financement
                    </a>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script>
import axios from 'axios'

export default {
    name: 'AsideComponent',
    data() {
        return {
            former: []
        }
    },

    mounted(){
        axios
        .get('https://ofpas-stephane-database.herokuapp.com/interventions/former')
        .then(response => {
            const queryData = response.data

            // TRIER LES MESSAGES PAR ID
            // function pour comparer les valeurs
            const sortByMapped = (map,compareFn) => (a,b) => compareFn(map(a), map(b));
            // base de la comparaison
            const byValue = (a,b) => a - b;
            // ce qu'on compare
            const toId = e => e.id;
            // on lance la comparaison
            const byId = sortByMapped(toId,byValue);
            // on sort le resultat
            this.former = queryData.sort(byId);
        })

        
    }
}
</script>

<style lang='scss'>
    aside {
        display: flex;
        flex-direction: column;
        width: 300px;
        height: auto;
        padding: 1rem;
        background-color: #f4f4f4;
        box-shadow: 0 0 10px #555;

        & .date-link {
            color: black;
            text-decoration: none;
        }

        & .next-date {
            margin-bottom: 1rem;
            padding: .2rem .8rem;
            border-radius: .4rem;
            background-color: #fff;
            font-size: .8rem;
            cursor: pointer;
            transition: .3s;

            &:hover {
                background-color: rgb(201, 201, 201);
            }

            &__title {
                font-weight: bold;
                margin: .6rem 0;
            }

            &__date {
                text-align: center;
            }
        }

        & h2 {
            margin: 1rem;
            padding-bottom: .4rem;
            border-bottom: 1px solid #999;
        }

        & ul {
            list-style: none;

            & li {
                margin: .6rem 0 .6rem .4rem;

                & a {
                    color: black;
                    text-decoration: none;
                    transition: .3s;

                    &:hover {
                        color: #0499D2;
                    }
                }
            }
        }
    }

@media screen and (max-width: 850px) {
    aside {
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 540px) {
    aside {
        flex-direction: column;

        & h2 {
            font-size: 1.2rem;
        }

        & a {
            font-size: .8rem;
        }
    }
}
</style>
