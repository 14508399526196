<template>
    <main class="post-detail">
        <h2 class="post-detail__title">{{ post.title }}</h2>
        <img class="post-detail__image" :src="post.image" alt="">
        <h3 class="post-detail__subtitle">{{ post.subtitle }}</h3>
        <p class="post-detail__content" v-html="post.content"></p>
    </main>
</template>

<script>
import axios from 'axios'

export default {
    name: "ArticleDetail",
    data() {
        return {
            post: null
        }
    },
    mounted(){
        axios
        .get(`https://ofpas-stephane-database.herokuapp.com/posts/${this.$route.params.id}`)
        .then(response => {
            this.post = response.data
        })
    },
}
</script>

<style lang='scss'>
.post-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;

    &__title {
        padding: 1rem;
        font-size: 1.8rem;
        color: #F15A2A;
    }

    &__image {
        width: 100%;
    }

    &__subtitle {
        padding: .4rem 1rem;
        font-size: 1.2rem;
        font-weight: 100;
        text-align: right;
    }

    &__content {
        margin-top: 2rem;
        line-height: 1.6;

        & ul, & ol {
            margin-left: 1.2rem;
        }
    }
}

@media screen and (max-width: 850px) {
    .post-detail {
        padding: 1.4rem;
    }
}

</style>