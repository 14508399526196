<template>
  <div id="app">
    <Header/>
    <div class="container">
      <router-view class="container__component"></router-view>
      <AsideComponent/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import AsideComponent from './components/Aside-component.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    AsideComponent,
    Footer
  }
}
</script>

<style lang='scss'>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
}

.container {
  display: flex;
  width: 100%;

  &__component {
    flex: 1;
  }
}

@media screen and (max-width: 850px) {
    .container {
      flex-direction: column;
    }
}
</style>
